<template>
  <container>
    <row>
      <column class="w-full">
        <div class="mb-8">
          <main-title
            v-if="title"
            type="secondary"
            :has-prefix-icon="!noPrefix"
          >
            {{ title }}
          </main-title>

          <rich-text-renderer
            v-if="contentBlock"
            :document="contentBlock"
            :parent-component="parentComponent"
          />
        </div>
      </column>
    </row>
  </container>
</template>

<script>
import VueTypes from 'vue-types'

import { MainTitle } from '~/components/molecules'
import { Container, Row, Column } from '~/components/grid'
import RichTextRenderer from '~/components/renderers/rich-text-renderer'

export default {
  components: {
    Container,
    Row,
    RichTextRenderer,
    MainTitle,
    Column,
  },
  props: {
    title: VueTypes.string,
    contentBlock: VueTypes.oneOfType([Object, String]).def({}),
    noPrefix: VueTypes.boolean,
    parentComponent: {
      type: String,
      default: 'freeFormat',
    },
  },
}
</script>
